import {Component, OnInit, Optional} from '@angular/core';
import { HttpClient } from '../../services/http.service';
import {GoBackButtonService} from '../../services/go-back-button.service';


@Component({
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {

  public show: any;
  public loading = true;

  constructor(public http: HttpClient, @Optional() private goBack: GoBackButtonService) {
    this.http.post('Import.check').subscribe(
      (response) => {
        this.show = response;
        this.loading = false;
      },
      (error) => {
        this.show = false;
        this.loading = false;
      }
    );
  }

  ngOnInit() {
    if (this.goBack) {
      this.goBack.header = '<h1>Автоматический импорт</h1>';
    }
  }

}
