
<div class="material-datatable-wrapper">
  <app-datatable-headline
    [items_per_page_vars]="items_per_page_vars"
    (items_per_page_change)="itemPerPageChange($event)"
    (global_search_change)="onGlobalSearch($event)">
  </app-datatable-headline>
  <div class="table-responsive overflow-visible">
    <table class="table datatable table-rwd">
      <thead app-data-table-head
             [columns]="columns"
             (sort_emmit)="onSortChange($event)">
      </thead>
      <tbody app-data-table-body
             [columns]="columns">
      </tbody>
    </table>
  </div>
  <app-datatable-pagination
    (change_page)="changePage($event)">
  </app-datatable-pagination>
</div>

<app-modal *ngIf="modal_show" [caption]="'Условия выплат субагенту'" (onClosed)="modalClose()" [footer]="footer">
  {{modal_content}}
</app-modal>
<ng-template #footer>
  <div class="col-md-12">
    <button (click)="modalClose()" class="button pull-right" type="button">Закрыть</button>
  </div>
</ng-template>

<ng-template #idHead let-column="column">
  <form [formGroup]="formGroup" novalidate class="inputfix">
    <app-material-input-autocomplete-custom
      formControlName="developer_caption"
      [api_action]="'Dict.developer_search_vitrina'"
      (autocopleteChange)="onAutocopleteChange('developer_caption', $event)"
      [nameLabel]="'description'"
      [nameValue]="'description'"
      [placeholder]="'Выберите застройщика'">
    </app-material-input-autocomplete-custom>
  </form>
</ng-template>
<ng-template #captionHead let-column="column">
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="realty_caption" [cssClass]="'mb-0'" [placeholder]="'Название'">
    </app-material-input>
  </form>
</ng-template>
<ng-template #addressHead let-column="column">
  <form [formGroup]="formGroup" novalidate>
    <app-material-input formControlName="realty_housing_address" [cssClass]="'mb-0'" [placeholder]="'Адрес объекта'">
    </app-material-input>
  </form>
</ng-template>

<ng-template #id_tmpl let-row="row">
  <ng-container *ngIf="row.developer_caption">
    <br>{{row.developer_caption}}
  </ng-container>
</ng-template>
<ng-template #realtyTmpl let-row="row" let-ind="row_index">
  <div [ngClass]="{'activeBox': goBack.activeHash === 'realty_caption-' + ind}">
    <a href="/housing2/house/{{row.realty_id}}"
       (click)="goBack.newLink('/housing2/house/' + row.realty_id, 'realty_caption-' + ind, $event)">
      {{row.realty_caption}}
    </a>
  </div>
</ng-template>
<ng-template #addressTmpl let-row="row">
    {{row.realty_housing_address}}
</ng-template>

<ng-template #buttonTmpl let-row="row">
  <a class="" href="javascript: void(0)"
     (click)="viewPayConditions(row.realty_pay_condition, $event)">
    Подробнее
  </a>
</ng-template>

<ng-template #commission_agentTmpl let-row="row">
  <ng-container *ngIf="row.agent_ddu_prc_from && row.agent_ddu_prc_from && (row.agent_ddu_prc_from !== row.agent_ddu_prc_to)">
    От {{row.agent_ddu_prc_from | toFixed2}}% до {{row.agent_ddu_prc_to | toFixed2}}%
  </ng-container>
  <ng-container *ngIf="row.agent_ddu_prc_from && (row.agent_ddu_prc_from === row.agent_ddu_prc_to)">
    {{row.agent_ddu_prc_from | toFixed2}}%
  </ng-container>
  <ng-container *ngIf="!row.agent_ddu_prc_from">
    -
  </ng-container>
</ng-template>


<ng-template #commission_SPNTmpl let-row="row">
  <ng-container *ngIf="row.spn_balance_sub_from && row.spn_balance_sub_from && (row.spn_balance_sub_from !== row.spn_balance_sub_to)">
    От {{row.spn_balance_sub_from | toFixed2}}% до {{row.spn_balance_sub_to | toFixed2}}%
  </ng-container>
  <ng-container *ngIf="row.spn_balance_sub_from && (row.spn_balance_sub_from === row.spn_balance_sub_to)">
    {{row.spn_balance_sub_from | toFixed2}}%
  </ng-container>
  <ng-container *ngIf="!row.spn_balance_sub_from">
    -
  </ng-container>
</ng-template>


<ng-template #commission_SPN_transferTmpl let-row="row">
  <ng-container *ngIf="row.spn_balance_trans_from && row.spn_balance_trans_from && (row.spn_balance_trans_from !== row.spn_balance_trans_to)">
    От {{row.spn_balance_trans_from | toFixed2}}% до {{row.spn_balance_trans_to | toFixed2}}%
  </ng-container>
  <ng-container *ngIf="row.spn_balance_trans_from && (row.spn_balance_trans_from === row.spn_balance_trans_to)">
    {{row.spn_balance_trans_from | toFixed2}}%
  </ng-container>
  <ng-container *ngIf="!row.spn_balance_trans_from">
    -
  </ng-container>
</ng-template>


<ng-template #srokRealisation let-row="row">
  <ng-container *ngIf="row.srok_realisation">
    {{row.srok_realisation}}
  </ng-container>
  <ng-container *ngIf="!row.srok_realisation">
    -
  </ng-container>
</ng-template>


<ng-template #tarif_log_tmpl let-row="row">
  <ng-container *ngIf="row.tarif_log">
    <a routerLink="./logs/{{row.realty_id}}">Логи</a>
  </ng-container>
</ng-template>
