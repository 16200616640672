import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataTableParamComponent } from '../../../modules/@datatable/components/datatable-param.component';
import {FormGroup, UntypedFormBuilder} from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpClient } from '../../../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../../services/notification.service';


@Component({
  selector: 'app-import-list',
  templateUrl: './import-list.component.html',
  styleUrls: ['./import-list.component.scss']
})
export class ImportListComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  public api_action = 'Import.dt_feeds';
  public columns: Array<{ [key: string]: any }>;
  public subscription: Subscription = new Subscription();

  @ViewChild('captionTmpl', {static: true}) private captionTmpl: TemplateRef<any>;
  @ViewChild('buttonTmpl', {static: true}) private buttonTmpl: TemplateRef<any>;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder, public notify: NotificationService) {
    super(http, route, router);
  }

  ngOnInit() {
    this.columns = [
      {'caption': 'Название', 'name': 'caption', 'template': this.captionTmpl, 'sortable': true},
      {'caption': 'ЖК/квартир', 'name': 'counts', 'sortable': false},
      {'caption': 'Создан', 'name': 'created', 'sortable': true},
      {'caption': 'Обновлен', 'name': 'updated', 'sortable': true},
      {'caption': 'Статус', 'name': 'status', 'sortable': true},
      {'caption': 'Кол-во несвязанных объектов', 'name': 'null_jumpers', 'sortable': true},
      {'caption': '', 'template': this.buttonTmpl}
    ];
    if (!this.customFilter) {
      this.customFilter = new FormGroup([]);
    }
    this.customFilter.addControl('inArchive', this.fb.control(false));

    super.ngOnInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

