import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { NotificationService } from '../../../../services/notification.service';
import { HttpClient } from '../../../../services/http.service';

@Component({
  templateUrl: './import-edit-report.component.html',
  styleUrls: ['./import-edit-report.component.scss']
})
export class ImportEditReportComponent implements OnInit {

  public feed_id: any;
  public data: any;
  public formGroup: UntypedFormGroup;
  public periodic_list: any;
  public loading = false;
  public load_data = false;
  public types = {'auto': 'Автоматически', 'manual': 'Вручную', 'no': 'Не импортировать'};

  constructor(public http: HttpClient, public fb: UntypedFormBuilder,
              public notify: NotificationService, public router: Router, public activatedRoute: ActivatedRoute) {
    this.periodic_list = this.activatedRoute.snapshot.data['periodic'];
    this.feed_id = activatedRoute.snapshot.parent.params['id'];
  }

  ngOnInit() {
    this.load_data = true;
    this.activatedRoute.snapshot.data['data'].subscribe(
      data => {
        this.load_data = false;
        this.data = data;
        this.formGroup = this.fb.group({
          'caption': [{value: this.data['caption'], disabled: false}, Validators.required],
          'periodic': [{value: this.data['periodic'], disabled: false}, Validators.required],
          'enabled': [{value: this.data['enabled'] !== false, disabled: false}, Validators.required],
          'settings_type_plans': [{value: this.data['settings_type_plans'], disabled: false}, Validators.required],
          'settings_type_prices': [{value: this.data['settings_type_prices'], disabled: false}, Validators.required],
          'settings_type_captions': [{value: this.data['settings_type_captions'], disabled: false}, Validators.required],
          'settings_type_squares': [{value: this.data['settings_type_squares'], disabled: false}, Validators.required],
          'settings_type_statuses': [{value: this.data['settings_type_statuses'], disabled: false}, Validators.required],
          'settings_type_structure': [{value: this.data['settings_type_structure'], disabled: false}, Validators.required],
    });
      }
    );
  }

  saveForm() {
    if (!this.formGroup.valid) {
      this.onError('Заполните обязательные поля');
      return false;
    }
    this.loading = true;
    const data = {
      'setting_id': this.feed_id,
      'data': this.formGroup.value,
    };
    this.http.post('Import.setting_summary_update', data).subscribe(
      (response) => {
        this.onSuccess(response);
        this.loading = false;
      },
      (error) => {
        this.onError(error);
        this.loading = false;
      }
    );
  }

  onDelete() {
    NotificationService.swalConfirm('Удалить?', 'Выбранный фид будет удален со всеми связями, Вы уверены?')
      .then(_ => {
        this.loading = true;
        this.http.post('Import.setting_delete', {'setting_id': this.feed_id}).subscribe(
          (response) => {
            this.notify.notifySuccess('Поздравляем', 'Фид успешно удален', 3000);
            this.loading = false;
            this.router.navigate(['/import']);
          },
          (error) => {
            this.onError(error);
            this.loading = false;
          }
        );
      })
      .catch(_ => false);
  }

  onSuccess(response?) {
    this.notify.notifySuccess('Поздравляем', 'Сводка успешно сохранена', 3000);
  }

  onError(error) {
    this.notify.notifyError('Ошибка', error, 5000);
  }

}
