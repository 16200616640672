import {AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { HttpClient } from '../../services/http.service';
import { SLIDE_UP_ANIMATION } from '../../animations/slide-up.animation';
import { TemplateService } from '../../services/template.service';
import { ConfigService } from '../../services/configuration.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DataTableComponent } from '../../modules/@datatable';
import { debounceTime } from 'rxjs/operators';
import {GoBackButtonService} from '../../services/go-back-button.service';


@Component({
  selector: 'app-tariff-map-table',
  templateUrl: './tariff-map-table.component.html',
  styleUrls: [
    './tariff-map-table.component.scss',
  ],
animations: [SLIDE_UP_ANIMATION]
})
export class TariffMapTableComponent extends DataTableComponent implements OnInit, AfterViewInit {
  public modal_show = false;
  public modal_content = '';
  public user: any;

  public api_action = 'Realty.tariff';
  @Input() public columns;

  @ViewChild('id_tmpl', {static: true})
  private id_tmpl: TemplateRef<any>;
  @ViewChild('realtyTmpl', {static: true})
  private realty_tmpl: TemplateRef<any>;
  @ViewChild('addressTmpl', {static: true})
  private address_tmpl: TemplateRef<any>;
  @ViewChild('presentationTmpl', {static: true})
  private presentation_tmpl: TemplateRef<any>;
  @ViewChild('commission_agentTmpl', {static: true})
  private commission_agentTmpl: TemplateRef<any>;
  @ViewChild('commission_SPNTmpl', {static: true})
  private commission_SPNTmpl: TemplateRef<any>;
  @ViewChild('commission_SPN_transferTmpl', {static: true})
  private commission_SPN_transferTmpl: TemplateRef<any>;
  @ViewChild('buttonTmpl', {static: true})
  private button_tmpl: TemplateRef<any>;
  @ViewChild('srokRealisation', {static: true})
  private srokRealisation: TemplateRef<any>;

  @ViewChild('idHead', {static: true})
  private idHead: TemplateRef<any>;
  @ViewChild('captionHead', {static: true})
  private captionHead: TemplateRef<any>;
  @ViewChild('addressHead', {static: true})
  private addressHead: TemplateRef<any>;
  @ViewChild('tarif_log_tmpl', {static: true})
  private tarif_log_tmpl: TemplateRef<any>;

  public formGroup: UntypedFormGroup;

  constructor(public http: HttpClient, private template: TemplateService, public goBack: GoBackButtonService,
              public notify: NotificationService, public config: ConfigService, public fb: UntypedFormBuilder) {
    super(http);
    this.formGroup = this.fb.group({
      'developer_caption': [''],
      'realty_caption': [''],
      'realty_housing_address': [''],
    });
    this.formGroup.valueChanges.pipe(debounceTime(500)).subscribe((data) => {
      this.loadList();
    });
  }

  prepareQuery() {
    const query = super.prepareQuery();
    query['filter']['fields'] = {
      'developer_caption': this.formGroup.value.developer_caption,
      'realty_caption': this.formGroup.value.realty_caption,
      'realty_housing_address': this.formGroup.value.realty_housing_address,
    };
    return query;
  }

  ngOnInit() {
    this.goBack.header = '<h1>Тарифная карта</h1>';
    this.columns = [
      {'name': 'developer_caption', 'caption': '№', 'sortable': false,
        'template': this.id_tmpl, 'templateHead': this.idHead},
      {'name': 'realty_caption', 'caption': 'Название',
        'template': this.realty_tmpl, 'sortable': true, 'templateHead': this.captionHead},
      {'name': 'realty_housing_address', 'caption': 'Адрес объекта', 'sortable': true,
        'template': this.address_tmpl, 'templateHead': this.addressHead},
      {'name': '', 'caption': 'Презентация', 'template': this.presentation_tmpl},

      {'name': 'commission_agent', 'caption': 'Вознаграждение агента', 'sortable': true, 'template': this.commission_agentTmpl},
      {'name': 'commission', 'caption': 'Вознаграждение СА', 'sortable': true},

      {'name': 'commission_balance_spn', 'caption': 'Остаток SPN', 'sortable': true, 'template': this.commission_SPNTmpl},

      {'name': 'srok_realisation', 'caption': 'Срок реализации', 'sortable': true, 'template': this.srokRealisation},

      {'name': '', 'caption': 'Условия выплат субагенту', 'template': this.button_tmpl},
      {'name': 'tarif_log', 'caption': 'Логи', 'template': this.tarif_log_tmpl},
    ];
    super.ngOnInit();
  }

  viewPayConditions(id: any, event) {
    event.stopPropagation();
    this.modal_show = true;
    this.modal_content = id;
  }

  modalClose() {
    this.modal_show = false;
  }

  onError(error) {
    // NotificationService.swalError('Ошибка', 'Ошибка:' + error);
    this.notify.notifyError('Нет доступа', 'У вас нет доступа к тарифной карте', 5000);
  }

  onEvent(event) {
    event.stopPropagation();
  }

  onAutocopleteChange(name, value) {
    this.formGroup.get(name).setValue(value);
  }
  ngAfterViewInit() {
    this.goBack.goScroll();
  }
}
