import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DataTableHeadlineComponent } from './datatable-headline.component';
import { DataTableBodyComponent } from './datatable-body.component';
import { DataTablePaginationComponent } from './datatable-pagination.component';

import { HttpClient } from '../../../services/http.service';
import { NavigationLoaderService } from '../../../shared/navigation-loader/navigation-loader.service';
import { Subscription } from 'rxjs';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-material-table',
  templateUrl: './datatable.component.html'
})
export class DataTableComponent implements OnInit, OnDestroy {
  public current_page = 1;
  public sort: { [key: string]: string } = {};

  public global_search_string = null;
  public refreshSubscribtion: Subscription;
  public custSubscription: Subscription = new Subscription();
  public header_info: any;
  public customFilterValues = {};

  @Input() public api_action = '';
  @Input() public api_params = null;
  @Input() public cssClass = '';
  @Input() public templateRight = null;
  @Input() public responsive = true;
  @Input() public multisortEvent = false;
  @Input() public refreshTable: EventEmitter<any> = new EventEmitter<any>();

  @Input() public items_per_page = 50;
  @Input() public items_per_page_vars: Array<number> = [10, 20, 50, 100];

  @Input() public columns: Array<any> = [];

  @Output() public checkLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output() public rowClick: EventEmitter<any> = new EventEmitter();
  @Input() public customFilter: UntypedFormGroup;
  @Input() public enable_global_search_string = true;

  @ViewChild(DataTableHeadlineComponent, {static: true})
  theadline: DataTableHeadlineComponent;

  @ViewChild(DataTableBodyComponent, {static: true})
  tbody: DataTableBodyComponent;

  @ViewChild(DataTablePaginationComponent)
  pagination: DataTablePaginationComponent;

  constructor(public http: HttpClient) { }

  custFilterLoadList() {
    this.loadList();
  }

  ngOnInit() {
    for (let i = 0; i < this.columns.length; i++) {
      this.tbody.fields.push({
        name: this.columns[i].name,
        template: this.columns[i].template,
      });
    }
    if (this.customFilter) {
      this.custSubscription.add(this.customFilter.valueChanges.subscribe((v) => {
        for (const [key, value] of Object.entries(v)) {
          this.customFilterValues[key] = value;
        }
        this.current_page = 1;
        this.custFilterLoadList();
      }));
    }
    this.refreshSubscribtion = this.refreshTable.subscribe(() => this.loadList());
    this.loadList();
  }

  onGlobalSearch(value) {
    this.global_search_string = value.length ? value : null;
    this.current_page = 1;
    this.loadList();
  }

  itemPerPageChange(count) {
    this.items_per_page = count;
    this.loadList();
  }

  changePage(page: number) {
    this.current_page = page;
    this.loadList();
  }

  getPagination() {
    const offset: number = (this.current_page - 1) * this.items_per_page;
    return {limit: this.items_per_page, offset: offset};
  }

  prepareQuery(): any {
    const query = this.getPagination();

    query['filter'] = Object.assign(this.customFilterValues, {
      'global_search_string': this.global_search_string,
    });
    query['sort'] = this.sort;
    return query;
  }

  loadList() {
    NavigationLoaderService.show();
    this.tbody.loading = true;
    this.http.post(this.api_action, this.prepareQuery())
      .subscribe((response) => {
        this.tbody.rows = response.rows;
        this.tbody.loading = false;
        if (this.theadline) {
          this.theadline.showed_lines = response.count;
        }

        if (response.header_info) {
          this.header_info = response.header_info;
        }

        this.pagination.update_pagination.next([response.count, this.items_per_page]);
        this.checkLoad.emit(true);
        NavigationLoaderService.hide();
      }, (error) => {
        NavigationLoaderService.hide();
      });
  }

  append(row: any) {
    this.tbody.rows.unshift(row);
  }

  onSortChange(sort: any) {
    this.sort = sort;
    this.loadList();
  }

  ngOnDestroy() {
    if (this.refreshSubscribtion) {
      this.refreshSubscribtion.unsubscribe();
    }
    this.custSubscription.unsubscribe();
  }
}
