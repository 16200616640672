import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {HttpClient} from '../../../services/http.service';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {OverlayWindowService} from '../../../_crm/single-window/shared/overlay-window.service';
import {NotificationService} from '../../../services/notification.service';
import {CalendarInviteBindService} from './calendar-invite-bind.service';

enum UsageVariant {
  Create,
  Edit
}

@Component({
  templateUrl: './calendar-invite-component.html',
  styleUrls: ['./calendar-invite-component.scss'],
})
export class CalendarInviteComponent implements OnDestroy {

  public readonly max_sms_char_count = 255;
  public target_length = 0;

  private usageVariant = UsageVariant.Create;

  private _invite_id: number;
  private template_id;

  public form;
  public caption;
  public subscription: Array<Subscription> = [];

  @Output('saveEmitter') public saveEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input('invite_id') public set invite_id(value) {

    if (value === null) {
      this.caption = 'Новый шаблон';
      this.initializeForm();
      return;
    }

    this._invite_id = value;

    this.usageVariant = UsageVariant.Edit;

    this.http.post('Calendar.get_template', {'invite_template_id': +value})
      .subscribe(x => {
        this.template_id = x['template_id'];
        this.caption = x['caption'];
        this.target_length = x['sms_text'].length;
        this.initializeForm(x);
      });
  }

  public get invite_id() {
    return this._invite_id;
  }

  constructor(private http: HttpClient, private fb: UntypedFormBuilder, private notify: NotificationService,
              private overlayWindowService: OverlayWindowService, private bind: CalendarInviteBindService) { }

  initializeForm(data = null) {
    if (this.usageVariant === UsageVariant.Edit) {
      this.form = this.fb.group({
        'caption': [data['caption'], Validators.required],
        // 'email_subj': [data['email_subj'], Validators.required],
        'email_text': [data['email_text'], Validators.required],
        'sms_text': [data['sms_text'], Validators.required],
      });
    } else {
      this.form = this.fb.group({
        'caption': ['', Validators.required],
        // 'email_subj': ['', Validators.required],
        'email_text': ['', Validators.required],
        'sms_text': ['', Validators.required],
      });
    }

    this.subscription.push(this.form.get('caption').valueChanges.subscribe(value => this.caption = value));
    this.subscription.push(this.form.get('sms_text').valueChanges.subscribe(x => this.target_length = x.length));
  }

  ngOnDestroy() {
    for (let i = 0; i < this.subscription.length; i++) {
      this.subscription[i].unsubscribe();
    }
  }

  onSubmit() {
    const form_data = this.form.value,
      method = this.usageVariant === UsageVariant.Edit
      ? 'Calendar.modify_invite_template'
      : 'Calendar.add_invite_template';

    if (this.usageVariant === UsageVariant.Edit) {
      form_data['invite_template_id'] = this.invite_id;
    }

    this.http.post(method, form_data).subscribe(_ => this.saveEmitter.emit());
  }

  bindPlacemark(value) {
    this.bind.bindEmitter.emit(value);
  }

}
