import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataTableParamComponent } from '../../../modules/@datatable/components/datatable-param.component';
import { UntypedFormBuilder  } from '@angular/forms';
import { OverlayWindowService } from '../../single-window/shared/overlay-window.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '../../../services/http.service';
import { NotificationService } from '../../../services/notification.service';
import {GoBackButtonService} from '../../../services/go-back-button.service';
import {FancyboxService} from '../../../shared/fancybox.service';


@Component({
  selector: 'app-action-archive',
  templateUrl: './action-archive.component.html',
  styleUrls: ['./action-archive.component.scss']
})
export class ActionArchiveComponent extends DataTableParamComponent implements OnInit, OnDestroy {

  public api_action = 'ActionBanners.get_archive';
  public columns: Array<{ [key: string]: any }>;

  constructor(public http: HttpClient, public route: ActivatedRoute, public router: Router,
              public fb: UntypedFormBuilder, private overlayWindowService: OverlayWindowService, private goBack: GoBackButtonService,
              public notify: NotificationService, private lightbox: FancyboxService) {
    super(http, route, router);
  }

  @ViewChild('buttonTmpl', {static: true}) private buttonTmpl: TemplateRef<any>;
  @ViewChild('fileTmpl', {static: true}) private fileTmpl: TemplateRef<any>;
  @ViewChild('fileIconTmpl', {static: true}) private fileIconTmpl: TemplateRef<any>;

  ngOnInit() {
    this.columns = [
      {'caption': 'Дата удаления', 'name': 'date_archive', 'sortable': false},
      {'caption': 'Иконка', 'name': 'icon_url', 'sortable': false, 'template': this.fileIconTmpl, 'width': '40px'},
      {'caption': 'Баннер', 'name': 'banner_url', 'sortable': false, 'template': this.fileTmpl, 'width': '40px'},
      {'caption': 'Название', 'name': 'title', 'sortable': false},
      {'caption': 'Автор удаления', 'name': 'who_archive', 'sortable': false},
      {'caption': '', 'template': this.buttonTmpl}
    ];
    super.ngOnInit();
  }

  ngOnDestroy() {
  }

  prepareQuery() {
    return super.prepareQuery();
  }

  displayImage(file_url) {
    this.lightbox.open([{'src': file_url, 'thumb': file_url, 'caption': ''}], 0);
  }

  restoreArchive(id) {
    NotificationService.swalConfirm('Вы уверены?', 'Выбранная акция будет восстановлена из архива')
      .then(() => {
        this.http.post('ActionBanners.archive',
          {
            'id': id,
            'is_archive': false
          }
        ).subscribe(
          (response) => {
            this.notify.notifySuccess('Акция успешно восстановлена', '', 2000);
            this.loadList();
          },
          (error) => this.notify.notifyError('Ошибка', error, 5000)
        );
      }).catch(() => false);
  }

}
